var prodcat = prodcat || {};
var generic = generic || {};
var site = site || {};

prodcat.data = prodcat.data || {};

(function ($) {
  Drupal.behaviors.eGiftV1 = {
    /**
     * Flag to ensure this JS is only attached once.
     * @type {boolean}
     */
    attached: false,

    /** inits the cashstar overlay with supplied data
     *  @param {Object} egiftData - the product data
     *  @returns {none} - returns nothing
     */
    initCstarOverlay: function (egiftData) {
      var self = this;

      $('.js-egift-cta-button')
        .off()
        .on('click', function (evt) {
          evt.preventDefault();
          self.fireCstarOverlay(egiftData);
        });
    },

    /** opens the cashstar egift overlay
     * @param {Object} egiftData - the product data
     * @returns {none} - returns nothing
     */
    fireCstarOverlay: function (egiftData) {
      window.cstar.openPbg({
        appUrl: egiftData.appUrl,
        modalZIndex: 999,
        productName: egiftData.prodName,
        productUrl: egiftData.prodUrl,
        productImageUrl: egiftData.prodImgUrl,
        productImageWidth: egiftData.prodImgWidth,
        productImageHeight: egiftData.prodImgHeight,
        productOriginalPrice: egiftData.prodPrice,
        productCategory: egiftData.category
      });
    },

    /** Sets up the eGift product data to be used in the cashstar overlay
     * @param {Object} skuBaseID - the Base ID for the sku we want to get data for
     * @returns {object} egiftData - product data needed for cashstar overlay
     */
    setEGiftData: function (skuBaseID) {
      var egiftData = {};
      var skuData = prodcat.data.getSku(skuBaseID);
      var prodData = prodcat.data.getProduct(skuData.PRODUCT_ID);
      var domain = window.location.hostname;

      domain = domain.split('.')[domain.split('.').length - 2] + '.' + domain.split('.')[domain.split('.').length - 1];
      var prodDomain = 'smashbox.com';
      var utmData =
        'utm_source=cashstar&utm_medium=other&utm_campaign=email_productegiftcard&utm_content=product-e-gift';

      if (domain === prodDomain) {
        egiftData.appUrl = 'https://smashbox-pbg.cashstar.com/';
      } else {
        egiftData.appUrl = 'https://smashbox-pbg.semi.cashstar.com/';
      }

      // Build the product URL with UTM values.
      // if the URL already contains a ?, then it already has params and we append the UTM values with an &
      // otherwise, we append them with a ?
      egiftData.prodUrl = window.location.href;
      var addQuestion = egiftData.prodUrl.indexOf('?') > 0 ? '&' : '?';

      egiftData.prodUrl = egiftData.prodUrl + addQuestion + utmData;

      egiftData.prodPrice = skuData.PRICE;
      egiftData.prodImgUrl = window.location.protocol + '//' + window.location.hostname + skuData.MEDIUM_IMAGE[0];
      egiftData.prodImgHeight = 388;
      egiftData.prodImgWidth = 396;
      egiftData.prodName = prodData.PROD_RGN_NAME;
      egiftData.category = prodData.PARENT_CAT_ID;

      if (prodData.shaded && skuData.SHADENAME) {
        egiftData.prodName = egiftData.prodName + ': ' + skuData.SHADENAME;
      }

      return egiftData;
    },

    /** @description: url.searchParams.get is not supported < IE Edge 17.
     * so rather than polyfill the w3c url API, let's just make our own.
     * @param {string} name - the name of the URL param we're fetching the value of
     * @returns {string} param value
     */
    urlParam: function (name) {
      var param = false;
      var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);

      if (results && results[1]) {
        param = decodeURIComponent(results[1]);
      }

      return param;
    },

    /** saves a cookie to tell other pages to display the redemption banner
     * @param {string} token - the token string extracted from the URL
     * @return {boolean} - if successful
     */
    setBannerCookie: function () {
      return generic.cookie('egift_banner_on', '1', { path: '/' });
    },

    /** sets a cookie determining the ongoing display of the redemption banner
     * @param {string} token - the egift_token value
     * @return {boolean} - if successful
     */
    setTokenCookie: function (token) {
      return generic.cookie('egift_token', token, {
        path: '/',
        secure: true
      });
    },

    /** sets a cookie determining the ongoing display of the redemption banner
     * @param {string} challenge - the challenge token value
     * @return {boolean} - if successful
     */
    setChallengeCookie: function (challenge) {
      return generic.cookie('egift_challenge', challenge, {
        path: '/',
        secure: true
      });
    },

    /* fires the overlay wherein the user enters they email to confirm they are the proper recipient
     * @param none
     * @return none
     */
    fireChallengeOverlay: function (token) {
      var self = this;
      var rendered = site.template.get({
        name: 'egift_challenge_overlay'
      });

      generic.overlay.launch({
        content: rendered,
        height: 460,
        width: '80%',
        cssClass: 'overlay__egift-challenge'
      });

      var overlayForm = $('.js-egift-challenge-form');

      overlayForm.on('submit', function (e) {
        e.preventDefault();
        self.clearOverlayError();
        var challenge = $('.js-egift-challenge-email').val();

        if (overlayForm[0].checkValidity()) {
          generic.jsonrpc.fetch({
            method: 'giftcard.getCardAndPin',
            params: [
              {
                TOKEN: token,
                CHALLENGE: challenge
              }
            ],
            onSuccess: function (jsonRpcResponse) {
              if (jsonRpcResponse.getValue()) {
                $.colorbox.close();
                self.storeTokens(token, challenge);
              } else {
                self.displayOverlayError();
              }
            },
            onFailure: function () {
              self.displayOverlayError();
            }
          });
        }
      });
    },

    /** clear the errors on the overlay form
     * @return {none} - returns nothing
     */
    clearOverlayError: function () {
      var overlayFormError = $('.js-challenge-overlay-error');

      overlayFormError.addClass('hidden');
    },

    /** display an error about a missed challenge token in the overlay
     * @return {none} - returns nothing
     */
    displayOverlayError: function () {
      var overlayFormError = $('.js-challenge-overlay-error');

      overlayFormError.removeClass('hidden');
    },

    /** detects whether or not the user is arriving via the redemption link in the recipient email
     * fires the redemption banner and stores the token in a cookie for later use in checkout
     * @return {none} - returns nothing
     */
    detectRedemption: function () {
      var token = this.urlParam('token');

      if (token && !this.detectCookies()) {
        this.fireChallengeOverlay(token);
      }
    },

    /** detects if the challenge and token cookies are set
     * if so, return true
     * @return {boolean} - true if the cookies are detected
     */
    detectCookies: function () {
      var found = false;

      if (generic.cookie('egift_challenge') && generic.cookie('egift_token')) {
        found = true;
      }

      return found;
    },

    /** stores the tokens retrieved from the url in cookies
     * @param {string} token - the token string for the cookie
     * @param {string} challenge - the challenge string for the cookie
     * @return {none} - returns nothing
     */
    storeTokens: function (token, challenge) {
      if (token && challenge) {
        Drupal.behaviors.eGiftBanner.fireRedemptionBanner();
        this.setTokenCookie(token);
        this.setChallengeCookie(challenge);
        this.setBannerCookie();
      }
    },

    /** stores the tokens retrieved from the url in cookies
     * @return {none} - returns nothing
     */
    initQuestionMarkTooltip: function () {
      // copied settings from product_ui.js
      var tooltipsterDefaultSettings = {
        animation: 'fade',
        arrow: false,
        contentAsHTML: true,
        interactive: true,
        multiple: true,
        onlyOne: true,
        position: 'top',
        restoration: 'none',
        speed: 500,
        theme: 'tooltipster-smashbox',
        timer: 3000,
        trigger: 'click',
        updateAnimation: false
      };
    },

    /**
     * Returns the skuId of the active shade.
     *
     * @returns {Int} The Id of the selected SKU, or the product's sku-base-id
     * if there are no .js-product-shade.active elements on the page.
     */
    getActiveShade: function () {
      var $product = $('.js-product-full');
      var skuId = false;

      if ($product) {
        var $activeShade = $('.js-product-shade.active', $product);

        if ($activeShade && $activeShade.data('sku')) {
          skuId = $activeShade.data('sku').substring(3);
        } else {
          skuId = $product.data('sku-base-id');
        }
      }

      return skuId;
    },

    /**
     * Calls all methods for setting up this template's JS.
     * Separated out in case it must be called after certain triggers too.
     * @return {none} - returns nothing
     */
    init: function () {
      var self = this;
      var page_data = page_data || false;
      var pathArray = window.location.pathname.split('/');
      var onProductUrl = pathArray[1] === 'product';

      // Drupal.settings.isSPP is set in smashbox_product.module.
      // Problem is, apparently not all SPP pages use the spp_product_layout
      // template, and so some products are left off. So we have to URL
      // detect as a backup. We still may lose some custom products.
      // Not sure how to handle those.
      if (Drupal.settings.isSPP || onProductUrl) {
        var skuBaseID = self.getActiveShade();

        if (skuBaseID) {
          self.initQuestionMarkTooltip();
          self.initCstarOverlay(self.setEGiftData(skuBaseID));
        }
      }

      $(document).on('product.skuSelect', '.js-product', function (event, skuBaseID) {
        self.initCstarOverlay(self.setEGiftData(skuBaseID));
      });

      this.detectRedemption();
    },

    /**
     * Standard method for attaching the template's JS.
     * @param {Object} context - the DOM scope, usually document.
     * @param {Object} settings - useful collection of site info.
     * @return {bool} - for dumping out early
     */
    attach: function (context, settings) {
      // I'd rather just not include this file in PG at all. But I don't know
      // how to do that. Do you? Can you tell me how?
      var pathArray = window.location.pathname.split('/');
      var in_pg = pathArray[1] === 'account' || pathArray[1] === 'checkout';

      if (this.attached || in_pg) {
        return;
      }
      this.attached = true;
      this.init();
    }
  };
})(jQuery);
